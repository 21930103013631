@import 'reset';
@import 'theme';

// NOTE: we are slowing phasing out the use of stylesheets in favor of themed components.
// See @material-ui/styles package for further information.

html {
  background-color: $black;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html,
#__next,
.page {
  color: $white;
  font-family: $font-family-body;
  font-size: 16px;
  line-height: calc(1.5 / 1);
  min-height: 100vh;
}

.page {
  min-height: inherit;
}

#nprogress {
  pointer-events: none;
  position: fixed;
  z-index: 1300;
}

#nprogress .bar {
  background: #38EF7D;
  height: 2px;
  left: 0;

  position: fixed;
  top: 0;

  width: 100%;
  z-index: 1031;
}

#nprogress .peg {
  box-shadow: 0 0 10px #38EF7D, 0 0 5px #38EF7D;
  display: block;
  height: 100%;
  opacity: 1;
  position: absolute;
  right: 0;
  transform: rotate(3deg) translate(0, -4px);
  width: 100px;
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@import 'components/Feedback';
@import 'components/Password';
@import 'components/AuthPage';

@import 'pages/legal';
@import 'pages/dashboard/account/styles';
@import 'pages/maintenance/styles';
@import 'components/Churnkey';
