div.input-password.legacy-input-styling {
  overflow: hidden;
  padding: 0;
  position: relative;

  input,
  input:hover,
  input:active,
  input:focus,
  input:disabled {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    height: 100%;
    height: auto;
    padding-right: 8px;
    width: calc(100% - 56px);
  }

  div[role='button'] {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding: 8px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 56px;

    p {
      margin: 0;
    }
  }
}
