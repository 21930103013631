#page-legal {
  background-color: $white;

  header {
    background-color: $black;
    text-align: center;
    width: 100%;

    @include tablet-and-smaller {
      padding-top: 0;
    }

    nav {
      align-items: center;
      display: flex;
      height: 80px;
      justify-content: space-between;
      padding: 0 48px;

      @include mobile {
        padding: 0 24px;
      }
    }

    .logo {
      background-image: url('/static/images/logo/type.svg');
      background-repeat: no-repeat;
      background-size: contain;
      height: 24px;
      width: 163px;

      &:hover {
        cursor: pointer;
      }
    }

    .icon-ex {
      height: 16px;
      width: 16px;
    }

    h2 {
      display: block;
      margin-top: 0;
      padding: 96px 48px;

      @include tablet-and-smaller {
        padding-bottom: 48px;
        padding-top: 24px;
      }

      @include mobile {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }

  main {
    color: $black;
    display: flex;

    @include tablet-and-smaller {
      display: block;
    }
  }

  select {
    display: block;
    margin: 24px auto;
    max-width: 400px;
    width: calc(100% - 48px);
  }

  aside {
    padding: 96px 0;

    > div {
      border-right: 2px solid $grey6;
      padding: 0 96px;
    }

    div {
      p {
        white-space: nowrap;
      }

      &:hover {
        cursor: pointer;

        p {
          color: $black;
        }
      }

      &:active p,
      &.active p {
        color: $color-primary;
      }
    }
  }

  article {
    padding: 96px;

    @include tablet-and-smaller {
      padding: 0 48px;
    }

    @include mobile {
      padding: 0 24px;
    }

    > :first-child {
      margin-top: 0;
    }

    ul {
      display: block;
      list-style-type: disc;
      margin: 1em 0;
      padding-left: 40px;
    }

    ol {
      counter-reset: item;
      list-style-type: none;
      margin: 0;
      padding: 0;

      > li {
        counter-increment: item;
        display: table;
        margin-bottom: 0.6em;

        &::before {
          content: counters(item, '.') '. ';
          display: table-cell;
          padding-right: 0.6em;
        }
      }
    }

    li ol > li {
      margin: 0;

      &::before {
        content: counters(item, '.') ' ';
      }
    }
  }
}
