// --------------------------------------------------------------
// Colors
$color-primary: #38EF7D;
$color-secondary: #11998E;
$color-error: #FF3366;
$color-warning: #FFC107;
$color-gradient: linear-gradient($color-primary, $color-secondary);
$box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
$black: #000000;
$white: #FFFFFF;
$grey1: #111111;
$grey2: #222222;
$grey3: #333333;
$grey4: #444444;
$grey5: #666666;
$grey6: #999999;

// --------------------------------------------------------------
// Spacing
$spacing-unit: 8px;
$padding-large: 96px;

// --------------------------------------------------------------
// Screens
$mobile-width: 640px;
$tablet-width: 768px;
$desktop-width: 1024px;
$large-desktop-width: 1380px; // Set based on the largest grid available.

// --------------------------------------------------------------
// Typeography
$font-family-title: 'Frank Ruhl Libre', serif;
$font-family-body: 'Assistant', sans-serif;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet-and-smaller {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet-and-larger {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin desktop-and-smaller {
  @media (max-width: #{$large-desktop-width - 1px}) {
    @content;
  }
}

@mixin large-desktop {
  @media (min-width: #{$large-desktop-width}) {
    @content;
  }
}

@mixin animation-spin {
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}
