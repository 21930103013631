#feedback {
  align-items: center;
  background-color: $grey2;
  border-radius: 16px;
  bottom: 24px;
  box-shadow: $box-shadow;
  box-sizing: border-box;
  color: $white;
  display: flex;
  justify-content: space-between;
  left: 50%;
  max-width: 400px;
  padding: 12px 16px;
  position: fixed;
  transform: translateX(-50%);
  width: calc(100% - 48px);
  z-index: 10000;

  // 48 + 16 + 32
  &:hover {
    opacity: 1;
    transition: opacity 0s !important; // Override react style.
  }

  .nav:hover {
    cursor: pointer;
  }

  .cta {
    margin-right: 16px;
  }

  .icon {
    min-height: 16px;
    min-width: 16px;
  }

  > div {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .message {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: 48px;

    > p {
      margin: 0;
    }

    .title {
      font-weight: bold;
    }
  }
}
