#auth-page {
  background-color: $black;
  box-sizing: border-box;
  min-height: 100vh;
  padding-top: 16px;
  width: 100%;

  @include tablet-and-smaller {
    height: auto;
    min-height: 100vh;
    padding-top: 0;
  }

  main {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 96px);
    overflow: hidden;

    @include tablet-and-smaller {
      min-height: calc(100vh - 80px);
    }

    a > p.small {
      color: $color-primary;
    }

    label:last-child {
      margin-bottom: 16px;
    }

    label > div {
      display: flex;
      justify-content: space-between;
    }

    aside {
      position: relative;

      @include tablet-and-smaller {
        display: none;
      }
    }

    article {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 96px;
      padding-top: 96px;

      @include tablet-and-smaller {
        padding-bottom: 24px;
        padding-top: 24px;
        width: 100%;
      }

      > form,
      .auth-page-form-container {
        width: 100%;

        > :last-child {
          margin-bottom: 0;
        }
      }

      .field {
        display: block;
        max-width: 400px;
        position: relative;
      }

      label > input {
        width: 100%;
      }

      label p {
        height: 16px;
      }

      header {
        margin-bottom: 32px;

        :first-child {
          margin-top: 0;
        }

        p {
          color: $grey6;
        }
      }
    }
  }
}
