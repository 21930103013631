#page-maintenance {
  $image-size: 456px;
  $image-size-offset: calc($image-size / 2);
  background-color: $black;
  background-image: url('/static/images/maintenance.png');
  background-position: center calc(100% + #{$image-size-offset});
  background-repeat: no-repeat;
  background-size: $image-size;
  display: flex;
  text-align: center;

  @include mobile {
    background-position: center calc(100% + 50vw);
    background-size: 100vw;
  }

  .container {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    max-width: 960px;
    min-height: 500px;
    padding-bottom: 208px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 3rem;

    .content {
      align-items: center;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;

      h1 {
        // TODO: determine if this should make its way into theme.scss
        @include desktop {
          font-size: 96px;
          line-height: 1;
        }
      }

      .message {
        // TODO: Resolve if we prefer an `rem` or `em`.
        color: $grey6;
        margin-top: 1.5rem;
      }
    }
  }
}
