// TODO: these styles should be merged into the corresponding components as part of the overall
// theming strategy
#dashboard-account-layout {
  .tabs {
    margin-top: 32px;
    padding-bottom: 32px;

    > main {
      padding-top: 48px;
      width: 600px;
    }
  }

  .widget {
    > p:first-child {
      margin-top: 0;
    }

    input,
    form {
      @media (min-width: 599.95px) {
        width: 400px;
      }
    }

    h5 {
      margin-bottom: 16px;
    }

    label {
      display: block;
      height: 86px;

      p {
        margin-top: 0;
      }
    }
  }

  #profile-widget {
    hr {
      margin-bottom: 24px;
      margin-top: 32px;
    }

    p.small + p {
      color: $grey5;
    }
  }
}
