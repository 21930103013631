// stylelint-disable selector-class-pattern
@import 'shared';

// --------------------------------------------------------------
// Typography
@import url('https://fonts.googleapis.com/css?family=Frank+Ruhl+Libre:400,700');
@import url('https://fonts.googleapis.com/css?family=Assistant:400,,700');
@import url('https://fonts.googleapis.com/css?family=DM+Sans:400,700');

h1 {
  font-family: $font-family-title;
  font-size: 4em;
  font-weight: bold;
  line-height: calc(4.5 / 4);
  margin-bottom: 32px;
}

h2 {
  font-family: $font-family-title;
  font-size: 3em;
  font-weight: bold;
  line-height: calc(3.5 / 3);
  margin-bottom: 24px;
  margin-top: 32px;
}

h3 {
  font-family: $font-family-title;
  font-size: 2em;
  font-weight: bold;
  line-height: calc(2.5 / 2);
  margin-bottom: 16px;
  margin-top: 24px;
}

h4 {
  font-family: $font-family-title;
  font-size: 1.5em;
  font-weight: bold;
  line-height: calc(2 / 1.5);
  margin-bottom: 8px;
  margin-top: 16px;
}

h5 {
  font-family: $font-family-body;
  font-size: 1em;
  font-weight: bold;
  line-height: calc(1.5 / 1);
  margin-bottom: 8px;
  margin-top: 8px;
}

h6 {
  font-family: $font-family-body;
  font-size: 0.75em;
  font-weight: bold;
  line-height: calc(1 / 0.75);
  margin: 8px 0;
}

a {
  color: $color-primary;
  display: inline-block;

  &:not(.MuiButton-root):not(.MuiTab-root):not(.Mui-focusVisible) {
    text-decoration: none;

    &:focus {
      outline: none;
      text-decoration: underline;
    }
  }
}

p {
  font-family: $font-family-body;
  font-size: 1em;
  line-height: calc(1.5 / 1);
  margin: 8px 0;

  &.large {
    font-size: 2em;
    line-height: calc(2.5 / 2);
  }

  &.medium {
    font-size: 1.5em;
    line-height: calc(2 / 1.5);
  }

  &.small {
    font-size: 0.75em;
    line-height: calc(1 / 0.75);
  }

  &.quote {
    font-family: $font-family-title;
    font-size: 1.5em;
    line-height: 1;
  }

  &.error {
    color: $color-error;
  }

  &.uppercase,
  &.nav,
  a.nav,
  span.uppercase {
    font-weight: bold;
    text-transform: uppercase;
  }

  &.nav,
  a.nav {
    color: $grey5;
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }

    &.selected,
    &:hover {
      color: $white;
    }

    &:active {
      color: $color-primary;
    }

    &.primary {
      color: $white;

      &.selected,
      &:hover {
        color: $color-primary;
      }
    }

    &.inverse {
      color: $grey6;

      &.selected,
      &:hover {
        color: $black;
      }
    }
  }
}

b {
  font-weight: bold;
}

// --------------------------------------------------------------
// Modules

.disabled {
  pointer-events: none;
}

button {
  align-items: center;
  background-color: transparent;
  border-radius: 6px;
  color: $white;
  display: inline-flex;
  font: inherit;
  font-size: 100%;
  justify-content: center;
  margin: 0;
  outline: 0;
  padding: 0 16px;
  vertical-align: baseline;

  &:hover {
    cursor: pointer;
  }
}

button.primary {
  border: 2px solid $grey4;
  box-shadow: 0 0 0 0 $color-primary;
  position: relative;
  transition: 0.2s all ease-in-out;

  &:hover {
    border: 2px solid $white;
  }

  &:disabled {
    pointer-events: none;
  }

  &:active {
    border: 2px solid $color-primary;
  }

  &:active,
  &:disabled {
    background-color: $color-primary;
    border: 2px solid $color-primary;
    box-shadow: 0 0 0 0 $color-primary;
  }

  &:hover {
    border: 2px solid $color-primary;
    box-shadow: 0 0 25px -1px $color-primary;
  }

  &.primary {
    border: 2px solid $color-primary;
  }

  p {
    margin: 0;

    &.nav:hover,
    &.nav.primary:hover {
      color: $white;
    }
  }

  .icon-loading + p {
    opacity: 0;
  }

  .icon-loading {
    border: 2px solid transparent;
    border-top: 2px solid $white;
    height: 16px;
    position: absolute;
    width: 16px;
  }
}

.divider {
  background-image: linear-gradient(to right, $color-primary, $color-secondary);
  border-radius: 4px;
  height: 8px;
  width: 48px;
}

.play-pause {
  height: 32px;
  width: 32px;

  .icon {
    height: 100%;
    width: 100%;
  }
}
