#ck-app {
  &.ck-style {
    color: $black;
    position: relative;
    z-index: 10000;
  }
}

#ck-pause-wall {
  position: relative;
  z-index: 10000;
}
